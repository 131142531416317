const spaces = / /g;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export const createId = (text: string) => {
  const trimmed = text.trim();
  if (trimmed.length === 0) {
    throw new Error('Text needs to have at least one character');
  }
  return trimmed.replace(spaces, '-');
};
