import React from 'react';
import { FeatureAppContextProviderProps, FeatureAppContextState } from './FeatureAppTypes';

const FeatureAppContext = React.createContext<FeatureAppContextState | null>(null);

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export function FeatureAppContextProvider({
  logger,
  inPageNavigationService,
  children,
}: React.PropsWithChildren<FeatureAppContextProviderProps>) {
  return (
    <FeatureAppContext.Provider value={{ logger, inPageNavigationService }}>
      {children}
    </FeatureAppContext.Provider>
  );
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
function getContext() {
  const context = React.useContext(FeatureAppContext);
  if (context === null) {
    throw Error('Did you forget to wrap your Feature App in <FeatureAppContextProvider>?');
  }
  return context;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export function useLogger() {
  return getContext().logger;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export function useInPageNavigationService() {
  return getContext().inPageNavigationService;
}
