/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import type { Logger } from '@feature-hub/logger';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service/lib/v1';
import { RenderMode, RenderModeServiceV1 } from '@volkswagen-onehub/audi-render-mode-service';
import { ContentContextProvider } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import {
  InPageNavigationServiceV1,
  defineInPageNavigationService,
} from '@volkswagen-onehub/audi-in-page-navigation-service';
import { App } from './components/App';

import { Content } from './FeatureAppTypes';
import { createId } from '../utils/createId';
import { FeatureAppContextProvider } from './FeatureAppContext';
import { AnchorProps } from './components/AnchorTypes';

interface Dependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
  readonly 'audi-in-page-navigation-service': InPageNavigationServiceV1;
}

/**
 * It holds a function to retrieve the State needed by the feature app when
 * it´s rendered on the server
 * It holds the State object created from the serialized state on the client
 *
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export type AsyncStateHolder = (() => Promise<AnchorProps | undefined>) | AnchorProps | undefined;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export const createInitialState = async (
  { anchorName: title }: Content,
  renderMode: RenderMode
): Promise<AnchorProps | undefined> => {
  try {
    return {
      id: createId(title),
      title,
      editMode: renderMode === RenderMode.EDIT,
    };
  } catch {
    return undefined;
  }
};

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-in-page-navigation-service': '^0.0.1',
    },
    externals: {
      '@audi/audi-ui-react': '^1.6.0',
      '@feature-hub/react': '^2.7.0',
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '^5.1.1',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [defineInPageNavigationService({ serviceEnabled: true })],

  create: ({ featureServices }: FeatureAppEnvironment<Dependencies, void>) => {
    const {
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      's2:logger': s2logger,
      'audi-content-service': contentService,
      'audi-render-mode-service': renderModeService,
      'audi-in-page-navigation-service': inPageNavigationService,
    } = featureServices;

    const logger = s2logger || console;
    logger.info('Feature App created.');

    const content = contentService.getContent();
    const renderMode = renderModeService?.getRenderMode() || RenderMode.DEFAULT;

    let asyncStateHolder: AsyncStateHolder;

    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            asyncStateHolder = await createInitialState(content, renderMode);
            serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
          }
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState);
      } else {
        logger.warn(
          'Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken'
        );
        asyncStateHolder = () => createInitialState(content, renderMode);
      }
    }

    return {
      render: () => {
        return (
          <AudiPlatformProvider>
            <ContentContextProvider contentService={contentService}>
              <FeatureAppContextProvider
                logger={logger}
                inPageNavigationService={inPageNavigationService}
              >
                <App asyncStateHolder={asyncStateHolder} logger={logger} renderMode={renderMode} />
              </FeatureAppContextProvider>
            </ContentContextProvider>
          </AudiPlatformProvider>
        );
      },
    };
  },
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor](https://github.com/oneaudi/fa-anchor). Update all of your dependencies now!
 */
export default featureAppDefinition;
